<template>
	<div class="box">
		<div class="main-top">
			<div class="logo">
				<img src="/assets/img/Sale2.svg" alt="">

				<div class="text">
					دیلـــــی تتــــر
				</div>
			</div>

			<div class="loginAndRegister">
				<div class="register">
					ثبت نام
				</div>

				<div class="login">
					ورود
				</div>
			</div>
		</div>

		<div class="notification">
			<div class="text">
				ظرفیت تتر باقیمانده امروز
			</div>

			<div class="currency">
				USDT
			</div>
		</div>

		<div class="main-content">
			<div class="content">
				<div class="content-cover">
					<div class="right">
						<div class="top">
							<div class="line"></div>

							تتر خودت رو گرون <span>بفـــــروش</span>
						</div>

						<div class="description">
							گه تا امروز گرون میخریدی و ارزون میفروختی دیگه این کار رو نکن
							ما با شما همراهیم
						</div>

						<div class="sale">
							همین حالا بفروش
						</div>

						<div class="top">
							<div class="line"></div>

							پشتیبانی دارین؟
						</div>

						<div class="description">
							‏24 ساعته در 7 روز هفته پاسخگوی شما هستیم
						</div>

						<div class="support">
							پشتیبانی تلگرام
						</div>
					</div>

					<div class="left">
						<div class="items">
							<div class="item">
								<div class="title">
									قیمت فروش به مـــا
								</div>

								<div class="price">
									60,150
								</div>

								<a href="#" class="sellIt">
									همین حالا بفروش
								</a>

								<div class="down">
									<div class="left">
										ظرفیت باقیمانده
									</div>

									<div class="right">
										<div class="currency">
											USDT
										</div>
									</div>
								</div>
							</div>

							<div class="item nobitex">
								<div class="text">
									نوبیتکس
								</div>

								<div class="price">
									60,500
								</div>
							</div>

							<div class="item coinEx">
								<div class="text">
									کوین اکس
								</div>

								<div class="price">
									60,600
								</div>
							</div>

							<div class="item wallex">
								<div class="text">
									والکس
								</div>

								<div class="price">
									60,700
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="descriptions">
				<div class="title">
					چرا خرید و فروش از ما؟
				</div>

				<div class="text">
					بیا تا امکانات ویژه ما رو بدونی
				</div>

				<div class="items col-12">
					<div class="item col-4">
						<img src="/assets/img/quick-cash.svg" alt="">

						<div class="item-title">
							معامله سریع و آسان
						</div>

						<div class="item-description">
							معاملات خود را در سریع ترین زمان ممکن و به آسان ترین شکل انجام دهید و در این راه ما با شما هستیم
						</div>
					</div>

					<div class="item col-4 special">
						<img src="/assets/img/exchange.svg" alt="">

						<div class="item-title">
							بهترین نرخ تبادل
						</div>

						<div class="item-description">
							ما این اطمینان را به شما می دهیم که بالاترین و بهترین نرخ تتر را در این سایت به شما خواهیم داد
						</div>
					</div>

					<div class="item col-4">
						<img src="/assets/img/secure.svg" alt="">

						<div class="item-title">
							پرداخت و دریافت امن
						</div>

						<div class="item-description">
							تمامی پرداخت و دریافت های سایت بصورت کاملا امن و بر روی پروتکل های ضد سرقت SSL انجام می پذیرد
						</div>
					</div>
				</div>
			</div>

			<div class="tetherSaleInSocialNetworks">
				<div class="title">
					دیلی تتر در شبکه های اجتماعی
				</div>

				<div class="text">
					ما با شما همراهیم
				</div>

				<div class="socialNetworks">
					<a href="#" class="item">
						<i class="fi fi-facebook"></i>
					</a>

					<a href="#" class="item">
						<i class="fi fi-twitter"></i>
					</a>

					<a href="#" class="item">
						<i class="fi fi-linkedin"></i>
					</a>
				</div>
			</div>

			<div class="about">
				<div class="left">
					<img src="/assets/img/Tether.png" alt="">
				</div>

				<div class="right">
					<div class="title">
						<div class="line"></div>

						تتر پول نقد دیجیتال <span>شمـــا</span>
					</div>

					<div class="text">
						Realcoin و در ابتدا USDT یک رمزارز پایه ثابت با پشتوانه یک دلار آمریکا است.<br> نماد انحصاری آن (Tether)
						تتر نامیده می‌شد، با این حال، این علامت<br> تجاری در سال 2014 تغییر نام یافت و در سال 2015 فعالیت خود را
						با نام تتر .آغاز کرد<br>
						این رمزارز پایدار به سرعت در حال رشد است و به یکی از محبوب‌ترین و<br> شناخته شده‌ترین ارزهای دیجیتال در
						سال های اخیر تبدیل شده است. ارزش<br> این ارز به دلیل داشتن پشتوانه دلار آمریکا ثابت است و همواره یک دلار
						.خواهد بود<br>
						بنابراین تتر یک نوع پول نقد دیجیتال است که به علت پشتوانه دلاری آن<br> گزینه‌ی مناسبی برای انجام معاملات
						است
					</div>

					<div class="study">
						همین حالا مطالعه کن
					</div>
				</div>
			</div>

			<div class="main-about">
				<div class="right">
					<div class="title">
						<div class="line"></div>

						درباره <span>دیلــی تتر</span>
					</div>

					<div class="text">
						Realcoin و در ابتدا USDT یک رمزارز پایه ثابت با پشتوانه یک دلار آمریکا است. نماد انحصاری آن (Tether) تتر نامیده می‌شد، با این حال، این علامت تجاری در سال 2014 تغییر نام یافت و در سال 2015 فعالیت خود را با نام تتر .آغاز کرد
						این رمزارز پایدار به سرعت در حال رشد است و به یکی از محبوب‌ترین و شناخته شده‌ترین ارزهای
					</div>
				</div>

				<div class="left">
					<div class="title">
						<div class="line"></div>

						لینک های <span>دیلــی تتر</span>
					</div>

					<div class="pages">
						<a href="#" class="page">ثبت نام</a>

						<a href="#" class="page">ورود</a>

						<a href="#" class="page">مطالعه</a>

						<a href="#" class="page">پشتیبانی تلگرام</a>
					</div>
				</div>

			</div>
		</div>

		<div class="copyright">
			تمام حقوق مادی و معنوی سرویس متعلق به دیلی تتر است
		</div>
	</div>
</template>

<style lang="scss">
@import "/public/assets/css/scss/variables";

@font-face
{
	font-family: "Yekan";
	src: url('../public/assets/fonts/Roocket_ir_Fonts/Yekan-Bakh-FaNum-04-Regular.woff');
}

body
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background: #141414;
	margin: 0;
	direction: rtl;
	font-family: Yekan;
	display: flex;
	width: 100%;

	@media(max-width: $responsive-menu-width)
	{
		background: #fff;
		padding: 0;
	}
}

@import "/public/assets/css/scss/variables";



.my-row
{
	display: flex;
	flex-wrap: wrap;
	margin: 0;
}

.wrapper
{
	max-width: 1270px;
	margin: auto;
}

.box
{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #1A1A1A;

	.main-top
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-right: 50px;
		padding-left: 50px;
		padding-top: 20px;
		margin-bottom: 20px;

		.logo
		{
			display: flex;
			justify-content: center;
			align-items: center;

			img
			{
				width: 50px;
				height: 50px;

				@media(max-width: $responsive-menu-width)
				{
					width: 35px;
					height: 35px;
				}
			}

			.text
			{
				margin-right: 15px;
				font-size: 30px;
				color: #F1F1F1;

				@media(max-width: $responsive-menu-width)
				{
					font-size: 20px;
				}
			}
		}

		.loginAndRegister
		{
			display: flex;
			justify-content: center;
			align-items: center;

			.register
			{
				background: $main-color;
				padding: 10px 20px;
				border-radius: 50px;
				transition: 0.5s;
				cursor: pointer;

				@media(max-width: $responsive-menu-width)
				{
					padding: 5px 10px;
				}

				&:hover
				{
					background: #141414;
					border: 1px solid $main-color;
					color: $main-color;
				}
			}

			.login
			{
				border: 1px solid $main-color;
				color: $main-color;
				padding: 10px 20px;
				border-radius: 50px;
				transition: 0.5s;
				margin-right: 10px;
				cursor: pointer;

				@media(max-width: $responsive-menu-width)
				{
					padding: 5px 10px;
				}

				&:hover
				{
					background: $main-color;
					color: #141414;
				}
			}
		}
	}

	.notification
	{
		background: $main-color;
		width: 100%;
		display: flex;
		align-items: center;
		text-align: right;
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 50px;
		padding-right: 50px;

		.text
		{
			font-weight: bold;

			@media(max-width: $responsive-menu-width)
			{
				font-size: 13px;
			}
		}

		.currency
		{
			border: 1px solid #015845;
			color: #015845;
			padding: 10px 20px 6px 20px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 15px;

			@media(max-width: $responsive-menu-width)
			{
				padding: 5px 10px 3px 10px;
			}
		}
	}

	.main-content
	{
		padding-right: 50px;
		padding-left: 50px;

		.content
		{
			background: url("../public/assets/img/bg.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-attachment: fixed;
			background-position: center;

			.content-cover
			{
				background: #1a1a1afa;
				color: #fff;
				padding-top: 50px;
				padding-bottom: 30px;
				display: flex;
				justify-content: space-between;

				@media(max-width: $responsive-menu-width)
				{
					flex-direction: column;
				}

				.right
				{
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.top
					{
						display: flex;
						align-items: center;
						font-size: 27px;
						margin-bottom: 20px;

						@media(max-width: $responsive-menu-width)
						{
							font-size: 22px;
						}

						.line
						{
							width: 20px;
							border-bottom: 5px solid $main-color;
							margin-left: 10px;
						}

						span
						{
							color: $main-color;
							margin-right: 5px;
						}
					}

					.description
					{
						color: #BBBBBB;

						@media(max-width: $responsive-menu-width)
						{
							text-align: right;
						}
					}

					.sale
					{
						background: $main-color;
						color: #015845;
						border-radius: 70px;
						padding: 10px 30px 10px 30px;
						margin-top: 20px;
						cursor: pointer;
						margin-bottom: 40px;
					}

					.support
					{
						border: 1px solid $main-color;
						color: $main-color;
						border-radius: 70px;
						padding: 10px 30px 10px 30px;
						margin-top: 20px;
						transition: 0.5s;
						cursor: pointer;

						&:hover
						{
							background: $main-color;
							color: #141414;
						}
					}
				}

				.left
				{
					.items
					{
						margin-left: 50px;
						display: flex;
						flex-direction: column;

						@media(max-width: $responsive-menu-width)
						{
							margin-top: 30px;
							margin-left: 0;
							justify-content: center;
							align-items: center;
						}

						.item
						{
							background: #4EF09D;
							width: 600px;
							height: 300px;
							border-radius: 30px;

							@media(max-width: $responsive-menu-width)
							{
								width: 400px;
								height: 200px;
							}

							.title
							{
								color: #015845;
								font-size: 22px;
								padding-top: 20px;

								@media(max-width: $responsive-menu-width)
								{
									font-size: 18px;
								}
							}

							.price
							{
								font-size: 80px;
								color: #015845;

								@media(max-width: $responsive-menu-width)
								{
									font-size: 45px;
								}
							}

							.sellIt
							{
								background: #015845;
								color: $main-color;
								padding: 10px 20px 10px 20px;
								width: 180px;
								border-radius: 70px;
								margin: auto;
								cursor: pointer;
								text-decoration: none;

								@media(max-width: $responsive-menu-width)
								{
									padding: 7px 12px;
									font-size: 13px;
								}
							}

							.down
							{
								display: flex;
								justify-content: space-between;
								align-items: center;
								background: rgb(120, 249, 183);
								height: 70px;
								border-radius: 0 0 30px 30px;
								margin-top: 32px;

								.left
								{
									color: #015845;
									font-weight: bold;
									margin-right: 20px;
								}

								.right
								{
									margin-left: 20px;

									.currency
									{
										border: 1px solid #015845;
										color: #015845;
										padding: 10px 20px 6px 20px;
										border-radius: 5px;
										display: flex;
										align-items: center;
										justify-content: center;
										margin-right: 15px;
									}
								}
							}

							&.nobitex
							{
								background: #0F2F28;
								display: flex;
								height: 61px;
								width: 550px;
								margin: auto;
								justify-content: space-between;
								align-items: center;
								color: #009F7D;
								border-radius: 0 0 25px 25px;

								@media(max-width: $responsive-menu-width)
								{
									width: 350px;
									margin-top: 41px;
								}

								.text
								{
									margin-right: 20px;
									color: #009F7D;
									font-size: 22px;
									font-weight: bold;
								}

								.price
								{
									font-size: 30px;
									margin-left: 20px;
									color: #009F7D;
									font-weight: bold;
								}
							}

							&.coinEx
							{
								background: #112A25;
								display: flex;
								height: 55px;
								width: 500px;
								margin: auto;
								justify-content: space-between;
								align-items: center;
								color: #009F7D;
								border-radius: 0 0 25px 25px;

								@media(max-width: $responsive-menu-width)
								{
									width: 300px;
								}

								.text
								{
									margin-right: 20px;
									color: #009F7D;
									font-size: 20px;
									font-weight: bold;
								}

								.price
								{
									font-size: 25px;
									margin-left: 20px;
									color: #009F7D;
									font-weight: bold;
								}
							}

							&.wallex
							{
								background: #0F231F;
								display: flex;
								height: 52px;
								width: 450px;
								margin: auto;
								justify-content: space-between;
								align-items: center;
								color: #009F7D;
								border-radius: 0 0 25px 25px;

								@media(max-width: $responsive-menu-width)
								{
									width: 250px;
								}

								.text
								{
									margin-right: 20px;
									color: #009F7D;
									font-size: 18px;
									font-weight: bold;
								}

								.price
								{
									font-size: 22px;
									margin-left: 20px;
									color: #009F7D;
									font-weight: bold;
								}
							}
						}
					}
				}
			}
		}

		.descriptions
		{
			margin: auto;
			padding-top: 90px;
			margin-right: 150px;
			margin-left: 150px;

			@media(max-width: $responsive-menu-width)
			{
				margin-right: 0;
				margin-left: 0;
			}

			.title
			{
				font-size: 50px;
				color: #DEDEDE;

				@media(max-width: $responsive-menu-width)
				{
					font-size: 30px;
				}
			}

			.text
			{
				font-size: 20px;
				color: #BBBBBB;
				margin-top: 10px;

				@media(max-width: $responsive-menu-width)
				{
					font-size: 17px;
				}
			}

			.items
			{
				display: flex;
				justify-content: center;
				align-items: center;

				@media(max-width: $responsive-menu-width)
				{
					margin-top: 30px;
					flex-direction: column;
				}

				.item
				{
					background: #06493B;
					border-radius: 20px;
					margin: 20px;
					margin-top: 0;
					padding: 20px;

					@media(max-width: $responsive-menu-width)
					{
						width: 100%;
					}

					&.special
					{
						margin-top: 120px;

						@media(max-width: $responsive-menu-width)
						{
							margin-top: 0;
						}
					}

					img
					{
						margin-top: 40px;
					}

					.item-title
					{
						color: #F9C45C;
						font-size: 20px;
						margin-top: 20px;
					}

					.item-description
					{
						color: #B8B164;
						margin: auto;
						width: 100%;
						margin-bottom: 50px;
						margin-top: 20px;
					}
				}
			}
		}

		.tetherSaleInSocialNetworks
		{
			margin-top: 60px;

			.title
			{
				font-size: 50px;
				color: #DEDEDE;

				@media(max-width: $responsive-menu-width)
				{
					font-size: 30px;
				}
			}

			.text
			{
				font-size: 20px;
				color: #BBBBBB;
				margin-top: 10px;
			}

			.socialNetworks
			{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				.item
				{
					background: #4EF09D;
					width: 40px;
					height: 40px;
					margin: auto;
					margin: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 5px;
					font-size: 20px;
					cursor: pointer;
					text-decoration: none;
					color: #1A1A1A;
					transition: 0.3s;

					&:hover
					{
						font-size: 25px;
					}
				}
			}
		}

		.about
		{
			display: flex;
			align-items: center;
			margin-top: 70px;

			@media(max-width: $responsive-menu-width)
			{
				flex-direction: column;
				justify-content: center;
			}

			.left
			{
				margin-left: 70px;

				@media(max-width: $responsive-menu-width)
				{
					margin-left: 0;
				}

				img
				{
					@media(max-width: $responsive-menu-width)
					{
						width: 300px;
						margin: auto;
					}
				}
			}

			.right
			{
				.title
				{
					display: flex;
					align-items: center;
					font-size: 27px;
					margin-bottom: 20px;
					color: #E3E3E3;

					.line
					{
						width: 20px;
						border-bottom: 5px solid $main-color;
						margin-left: 10px;
					}

					span
					{
						color: $main-color;
						margin-right: 5px;
					}
				}

				.text
				{
					text-align: right;
					line-height: 35px;
					color: #BBBBBB;
				}

				.study
				{
					background: $main-color;
					padding: 10px 20px;
					border-radius: 50px;
					transition: 0.5s;
					cursor: pointer;
					width: 200px;
					margin-top: 20px;

					&:hover
					{
						background: #141414;
						border: 1px solid $main-color;
						color: $main-color;
					}
				}
			}
		}

		.main-about
		{
			display: flex;
			align-items: center;
			margin-bottom: 30px;

			@media(max-width: $responsive-menu-width)
			{
				flex-direction: column;
				align-items: flex-start;
			}

			.left
			{
				text-align: right;
				margin-right: 25px;

				@media(max-width: $responsive-menu-width)
				{
					margin-top: 30px;
				}

				.title
				{
					display: flex;
					align-items: center;
					font-size: 27px;
					margin-bottom: 20px;
					color: #E3E3E3;

					@media(max-width: $responsive-menu-width)
					{
						font-size: 20px;
					}

					.line
					{
						width: 20px;
						border-bottom: 5px solid $main-color;
						margin-left: 10px;
					}

					span
					{
						color: $main-color;
						margin-right: 5px;
					}
				}

				.pages
				{
					display: flex;
					flex-direction: column;

					.page
					{
						text-decoration: none;
						color: $main-color;
						margin-bottom: 10px;
					}
				}
			}

			.right
			{
				@media(max-width: $responsive-menu-width)
				{
					margin-top: 50px;
				}

				.title
				{
					display: flex;
					align-items: center;
					font-size: 27px;
					margin-bottom: 20px;
					color: #E3E3E3;

					@media(max-width: $responsive-menu-width)
					{
						font-size: 20px;
					}

					.line
					{
						width: 20px;
						border-bottom: 5px solid $main-color;
						margin-left: 10px;
					}

					span
					{
						color: $main-color;
						margin-right: 5px;
					}
				}

				.text
				{
					width: 700px;
					color: #BBBBBB;
					text-align: right;
					line-height: 35px;

					@media(max-width: $responsive-menu-width)
					{
						width: 400px;
					}
				}
			}
		}
	}

	.copyright
	{
		background: #0F0F0F;
		color: #747474;
		padding: 20px;
	}
}
</style>
