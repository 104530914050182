import { createApp } from 'vue'
import App from './App.vue'
/*import axios from "axios";*/

//import router from "./routes";

const app=createApp(App);

/*//axios.defaults.baseURL='https://crypto-core.jamkaran.org/';
axios.defaults.baseURL='http://192.168.1.31/CryptoPayment/Core/core/';
//axios.defaults.baseURL='http://192.168.1.26/CryptoPayment/Core/';
app.config.globalProperties.axios=axios*/

//app.use(router);

app.mount('body')
